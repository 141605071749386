import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Email from '@material-ui/icons/Email';
import LinkedIn from '@material-ui/icons/LinkedIn';
import PhoneEnabled from '@material-ui/icons/PhoneEnabled';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { ProfilePicture } from './ProfilePicture';
import { EProfileAttributes, ITalentProfile } from '../../store/reducers/ProfileReducer';

interface IProfile {
  profileData: ITalentProfile;
}

const useStyles = makeStyles({
  preferredTimes: {
    marginTop: (props: { mobile: boolean }) => props.mobile && 30,
    padding: `15px 25px !important`,
    borderRadius: 20,
    background: Colors.backgroundLightPurple,
    height: 'fit-content',
  },
  profileImage: {
    marginRight: 15,
  },
});

export const Profile: React.FC<IProfile> = ({ profileData }) => {
  const styles = useStyles({ mobile: !!useTheme().breakpoints.down('sm') });

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Grid item className={styles.profileImage}>
              <ProfilePicture src={profileData?.[EProfileAttributes.IMAGE]} />
            </Grid>
            <Grid item xs={9} container direction="column">
              <Grid item>
                <Text variant="lg" color="purple" fontWeight={600}>
                  {profileData?.[EProfileAttributes.FIRST_NAME]}{' '}
                  {profileData?.[EProfileAttributes.LAST_NAME]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4} marginBottom={4}>
                  {profileData?.[EProfileAttributes.JOB_TITLE]}
                </Text>
              </Grid>
              <Grid item container alignItems="center">
                <Text variant="xs" color="tertiaryGrey" display="inline">
                  {profileData?.[EProfileAttributes.ORGANISATION]}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="xs" marginTop={4}>
                  {profileData?.[EProfileAttributes.LOCATION]}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} container direction="column" spacing={1}>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <Email />
            </Grid>
            <Grid item xs={10}>
              <Text variant="sm" fontWeight={500} color="secondaryGrey">
                {profileData?.[EProfileAttributes.CONTACT_EMAIL]}
              </Text>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <PhoneEnabled />
            </Grid>
            <Grid item xs={10}>
              <Text variant="sm" fontWeight={500} color="secondaryGrey">
                {profileData?.[EProfileAttributes.PHONE_NUMBER]}
              </Text>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={2} md={1}>
              <LinkedIn />
            </Grid>
            <Grid item xs={10}>
              <BrancherLinkText
                target="_blank"
                variant="sm"
                color="secondaryGrey"
                wordBreak
                fontWeight={500}
                href={profileData?.[EProfileAttributes.LINKEDIN]}
              >
                {profileData?.[EProfileAttributes.LINKEDIN]?.split('/')[4]}
              </BrancherLinkText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BrancherDivider marginTop={40} marginBottom={40} />
      </Grid>
    </Grid>
  );
};
