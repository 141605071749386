export enum ProgramPositions {
  mentee = 'mentee',
  mentor = 'mentor',
}

export const ProgramPositionOptions = [
  { value: ProgramPositions.mentee, label: 'Mentee' },
  { value: ProgramPositions.mentor, label: 'Mentor' },
];

export const ExtendedProgramPositionOptions = [
  ...ProgramPositionOptions,
];

export enum ProgramPairPositions {
  mentees = 'mentees',
  mentors = 'mentors',
}

export const ProgramPairOptions = [
  { value: ProgramPairPositions.mentees, label: 'Mentees' },
  { value: ProgramPairPositions.mentors, label: 'Mentors' },
];

export enum SuperUserPositions {
  BRANCHER_ADMIN = 'brancherAdmin',
  ADMIN = 'admin',
  DEMO = 'demo',
}

export enum AllPositions {
  mentee = 'mentee',
  mentor = 'mentor',
  BRANCHER_ADMIN = 'brancherAdmin',
  TALENT_ADMIN = 'talentAdmin',
  ADMIN = 'admin',
  DEMO = 'demo',
}

export enum BrancherPlatformType {
  mentoring = 'mentoring',
  talent = 'talent',
  admin = 'admin',
}
