import * as React from 'react';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { IBrancherTextField } from './BrancherTextField';

interface IBrancherPhoneNumberField extends
  Pick<IBrancherTextField, 'updateValue' | 'fullWidth' | 'label' | 'placeholder' | 'value' | 'name' | 'required' | 'error' | 'helperText'>,
  MuiPhoneNumberProps {
}

export const BrancherPhoneNumberField: React.FC<IBrancherPhoneNumberField> = (props) => {
  const { value, updateValue, ...other } = props;

  const updatePhone = (phoneNumber: string, phoneInfo: { name: string; dialCode: string; countryCode: string; }) => {
    if(phoneNumber.replace(' ', '')?.indexOf('+6104') !== -1) {
      const aussieLocaleSimplified = phoneNumber.replace('0', '');
      updateValue(aussieLocaleSimplified);
    } else {
      updateValue(phoneNumber);
    }
  };

  return (
    <MuiPhoneNumber
      {...other}
      defaultCountry="au"
      excludeCountries={['ca']} // as we are including north america now
      preferredCountries={['au','nz']}
      regions={['oceania', 'north-america']}
      value={value}
      onChange={updatePhone}
    />
  );
};
