import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { BrancherDispatch, UtilSaveTalentJobPost } from '../../store/actions';
import { EJobStatus, IJobPost } from '../../store/actions/JobPostActions';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { CreateButton } from '../../Components/InputFields/BrancherButton';

interface ICreateJobPost {
  setClose: (jobPost: IJobPost) => void;
}

export const CreateJobPost: React.FC<ICreateJobPost> = ({setClose}) => {
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [status, setStatus] = React.useState<EJobStatus>(EJobStatus.DRAFT);
  const [creatingJob, setCreatingJob] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const createJobPost = () => {
    setCreatingJob(true);
    BrancherDispatch(dispatch, UtilSaveTalentJobPost({title, description, status, jobData: {}}, (jobPost) => {
      setCreatingJob(false);
      setClose(jobPost.data);
    }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid container spacing={4} item xs={10}>
        <Grid item xs={12}>
          <BrancherTextField fullWidth name="jobTitle" value={title} updateValue={setTitle} label="Job title" />
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField multiline rows={3} fullWidth name="jobDescription" value={description} updateValue={setDescription} label="Job description" />
        </Grid>
        <Grid item xs={12}>
          <BrancherSelect
            name="jobStatus"
            value={status}
            fullWidth
            updateValue={(s: EJobStatus) => setStatus(s)}
            options={[
              {
                value: EJobStatus.DRAFT,
                label: EJobStatus.DRAFT,
              },
              {
                value: EJobStatus.OPEN,
                label: EJobStatus.OPEN,
              },
              {
                value: EJobStatus.CLOSED,
                label: EJobStatus.CLOSED,
              },
              {
                value: EJobStatus.CANCELLED,
                label: EJobStatus.CANCELLED,
              }
            ]}
            inputLabel="Job status"
          />
        </Grid>
        <Grid item xs={12}>
          <CreateButton disabled={creatingJob} onClick={createJobPost} fullWidth>
            Create Job
          </CreateButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
