import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import {Text} from '../General/Text';

const useStyles = makeStyles({
  errorText: {
    zIndex: 20,
  },
  errorRoot: {
    maxHeight: '100vh',
    height: '100vh',
  }
});

interface IBaseErrorPage {
  children: React.ReactNode;
  error: string | number;
  errorHeading: string;
  errorDescription: string;
  src?: string;
}

export const BaseErrorPage = (props: IBaseErrorPage) => {
  const { children, error, errorDescription, errorHeading, src } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();
  
  const graphic = src ?? 'https://media.brancher.com.au/shared/error-page.png';
    
  return (
    <Grid container justifyContent="flex-start" alignItems="center" className={styles.errorRoot}>
      <Grid item xs={7} container alignItems="center" justifyContent="center" className={styles.errorText}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={7}>
            <Text variant="xxxl" fontWeight={700}>
              { error }
            </Text>
          </Grid>
          <Grid item xs={7}>
            <Text variant="xl" fontWeight={700} marginTop={mobile ? 10 : 30} marginBottom={mobile ? 10 : 30} color="purple">
              {errorHeading}
            </Text>
          </Grid>
          <Grid item xs={7}>
            <Text variant="lg" marginBottom={mobile ? 10 : 30}>
              {errorDescription}
            </Text>
          </Grid>
          <Grid item xs={7}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Box position="absolute">
        <img
          src={graphic}
          alt="error-page-graphic"  width="100%" height="auto"
        />
      </Box>
    </Grid>
  );
};
