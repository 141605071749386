import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Done from '@material-ui/icons/Done';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IValidatePassword, IValidationRules, validatePassword } from '../../utils/validators/validatePassword';
import { Text } from './Text';

interface IPasswordValidation {
  anchorEl: Element;
  password: string;
  passwordValidationRules?: IValidationRules;
}

export const defaultPasswordRules: IValidationRules = {
  minCharacters: 8,
  minNumbers: 1,
  minLowerCase: 1,
  minUpperCase: 1,
  minSpecialCharacters: 0,
};

export const PasswordValidation = (props: IPasswordValidation) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { anchorEl, password, passwordValidationRules = defaultPasswordRules } = props;

  const open = Boolean(anchorEl);

  const validated: IValidatePassword = validatePassword(password, passwordValidationRules);

  const addPlural = (validationRuleAmount: number): string => {
    if(validationRuleAmount > 1) {
      return 's';
    } else {
      return '';
    }
  };

  return (
    <Popover
      id="password-validator"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: mobile ? 'top' : 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: mobile ? 'bottom' : 'center',
        horizontal: 'left',
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <ValidatePasswordText title={`Require at least ${passwordValidationRules.minCharacters} characters`} valid={validated.containsEnoughCharacters} />
      {passwordValidationRules.minNumbers > 0 &&
      <ValidatePasswordText
        title={`Require ${passwordValidationRules.minNumbers} number${addPlural(passwordValidationRules.minNumbers)}`}
        valid={validated.containsNumber}
      />
      }
      {passwordValidationRules.minUpperCase > 0 &&
      <ValidatePasswordText
        title={`Require ${passwordValidationRules.minUpperCase} uppercase letter${addPlural(passwordValidationRules.minUpperCase)}`}
        valid={validated.containsUppercase}
      />
      }
      {passwordValidationRules.minLowerCase > 0 &&
      <ValidatePasswordText
        title={`Require ${passwordValidationRules.minLowerCase} lowercase letter${addPlural(passwordValidationRules.minLowerCase)}`}
        valid={validated.containsLowercase}
      />
      }
      {passwordValidationRules.minSpecialCharacters > 0 &&
      <ValidatePasswordText
        title={`Requires ${passwordValidationRules.minSpecialCharacters} special character${addPlural(passwordValidationRules.minSpecialCharacters)}`}
        valid={validated.containsSpecialCharacter}
      />
      }
    </Popover>
  );
};

interface IValidatePasswordText {
  title: string;
  valid: boolean;
}

const ValidatePasswordText = (props: IValidatePasswordText) => {
  const { title, valid } = props;
  return (
    <Box alignItems="center" display="flex" flexDirection="row" marginBottom={1}>
      <Done color={valid ? 'inherit' : 'disabled'} />
      <Text display="inline" variant="sm" fontWeight={600} color={valid ? 'purple' : 'black'} marginLeft={15}>
        {title}
      </Text>
    </Box>
  );
};
