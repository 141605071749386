import * as React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Colors } from '../../consts/colors';
import { BackSplash } from '../../helpers/LinkHelpers';

const useStyles = makeStyles({
  splash: {
    position: 'fixed',
    zIndex: 1,
    minHeight: `100vh`,
    width: '100%',
  },
  overlay: {
    zIndex: 2,
    backgroundColor: `rgba(80,0,255, 0.7)`,
    position: 'fixed',
    minHeight: `100vh`,
    width: '100%',
  },
  main: {
    zIndex: 10,
    backgroundColor: Colors.white,
    width: (props: { mobile: boolean }) => (props.mobile ? '88%' : 800),
    borderRadius: 30,
    padding: (props: { mobile: boolean }) => (props.mobile ? '50px' : `60px 100px`),
  },
});

export const BackSplashImagery = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  return (
    <>
      <div className={styles.main}>{children}</div>
      <img src={BackSplash} className={styles.splash} alt='background-splash' />
      <div className={styles.overlay} />
    </>
  );
};
