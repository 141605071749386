import * as React from 'react';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

export interface IBrancherTextField extends StandardTextFieldProps {
  value: string | number;
  updateValue: (e: string) => void;
  placeholder?: string;
  name?: string;
  readableName?: string;
  helpText?: string | React.ReactNode;
  maxChars?: number;
  max?: number;
  min?: number;
}

export const BrancherTextField = (props: IBrancherTextField) => {
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const { updateValue, type, helpText, maxChars, readableName, max = 1000, min = 0, ...other } = props;

  const onChange = (e) => {
    if(type === 'number' && ((Number(e.target.value) <= max) && (Number(e.target.value) >= min))) {
      updateValue(e.target.value);
    } else if(type !== 'number' && ((maxChars && e.target.value?.length <= maxChars) || !maxChars)) {
      updateValue(e.target.value);
    } else if(type === 'number' && (!!max && !!min)) {
      updateValue(e.target.value);
    }
  };

  const toggleVisible = () => {
    setVisiblePassword(!visiblePassword);
  };

  return (
    <TextField
      onChange={onChange}
      InputProps={{
        'aria-describedby': other.name,
        endAdornment:
          type === 'password' && !visiblePassword ? (
            <IconButton onClick={toggleVisible}>
              <VisibilityOff />
            </IconButton>
          ) : type === 'password' ? (
            <IconButton onClick={toggleVisible}>
              <Visibility />
            </IconButton>
          ) : (
            <></>
          ),
      }}
      type={(type === 'password' && !visiblePassword) || type !== 'password' ? type : 'text'}
      helperText={helpText || (maxChars && type === 'number' && `${other.value.toString().length ? other.value.toString().length : 0}/${maxChars}`)}
      {...other}
    />
  );
};
