import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { useLocation } from 'react-router';
import { BrancherDispatch, UtilGetTalentJobPost } from '../../store/actions';
import { Page } from '../../Components/General/Page';
import { useDispatch } from 'react-redux';
import { IJobPost } from '../../store/actions/JobPostActions';

export const Job: React.FC = () => {
  const jobId = new URLSearchParams(useLocation().search).get('j');
  const [gettingJob, setGettingJob] = React.useState<boolean>(false);
  const [job, setJob] = React.useState<IJobPost>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!!jobId) {
      setGettingJob(true);
      BrancherDispatch(dispatch, UtilGetTalentJobPost(jobId, (retrievedJob) => {
        setJob(retrievedJob.data)
        setGettingJob(false);
      }));
    }
  }, []);

  return (
    <Page loading={gettingJob}>
      <Grid container alignItems="center" justifyContent="center">
        <Text variant="xl" marginBottom={30} marginTop={30} fontWeight={600} color="purple">
          Job details
        </Text>
      </Grid>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={4}>
        <Grid item>
          <Text variant="md" marginBottom={30} marginTop={30} fontWeight={500} color="purple" display="inline">
            Title:
          </Text>
          <Text variant="md" color="purple" display="inline" fontWeight={700}> {job?.title}</Text>
        </Grid>
        <Grid item>
          <Text variant="md" marginBottom={30} marginTop={30} fontWeight={500} color="purple" display="inline">
            Description:
          </Text>
          <Text variant="md" color="purple" display="inline" fontWeight={700}> {job?.description}</Text>
        </Grid>
        <Grid item>
          <Text variant="md" marginBottom={30} marginTop={30} fontWeight={500} color="purple" display="inline">
            Status:
          </Text>
          <Text variant="md" color="purple" display="inline" fontWeight={700}> {job?.status}</Text>
        </Grid>
      </Grid>
    </Page>
  );
};
