import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { JobPosts } from './JobPosts';

export const Jobs: React.FC = () => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Text variant="xxl" marginBottom={30} marginTop={30} fontWeight={600} color="purple">
          Available Jobs
        </Text>
      </Grid>
      <JobPosts />
    </Grid>
  );
};
