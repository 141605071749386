import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest } from './utils/BrancherDispatch';
import { ITalentProfile, PROFILE_CLEAR_DATA, PROFILE_DATA } from '../reducers/ProfileReducer';

export const SaveProfileData = (profileData: object) => {
  return {
    type: PROFILE_DATA,
    payload: profileData,
  };
};

export const ClearProfileData = () => {
  return {
    type: PROFILE_CLEAR_DATA,
  };
};

// This is for when admins want to look at a specific profile
export const UtilGetTalentProfile = (userId: string, cb: (a: ITalentProfile) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/profile',
        params: JSON.stringify({
          userId
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const { profileData } = response.data.data;
        cb(profileData);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This grabs the userId from the bearer token
export const UtilGetUserTalentProfile = (cb: (a: ITalentProfile) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/profile',
        params: JSON.stringify({}),
      },
      getState(),
    )
      .then((response: any) => {
        const profile = response.data.data;
        dispatch(SaveProfileData(profile));
        cb(profile);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilSaveTalentProfile = (
  profile: ITalentProfile,
  actualCompletedProfile: boolean,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const userId = getState().user.id;
    const companyId = getState().user.companyId;
    const profilePic = getState().profile.newProfilePic;
    const { completedProfile, ...profileDataSave } = profile;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'talent/profile',
        data: {
          userId,
          companyId,
          completedProfile: actualCompletedProfile,
          profileData: {...profileDataSave},
          newProfilePic: profilePic,
        },
      },
      getState(),
    )
      .then((response: any) => {
        dispatch(
          SaveProfileData({ ...profileDataSave, completedProfile: actualCompletedProfile }),
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilSetupTalentProfile {
  data: ITalentProfile['baseProfile'];
}

export const UtilSetupTalentProfile = (cb: (a: ITalentProfile['baseProfile']) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/profilesetup',
        params: JSON.stringify({}),
      },
      getState(),
    )
      .then((response: { data: IUtilSetupTalentProfile }) => {
        cb(response.data.data);
      })
      .catch(() => {});
  };
};
