import { IReducerType } from './ReducerType';

export const PROFILE_CLEAR_DATA = 'PROFILE_CLEAR_DATA';
export const PROFILE_DATA = 'PROFILE_DATA';

export const profileReducerInitialState: IProfileInfo = {completedProfile: false};

export const ProfileReducer = (state = profileReducerInitialState, action: IReducerType) => {
  switch (action.type) {
    case PROFILE_CLEAR_DATA:
      return profileReducerInitialState;
    case PROFILE_DATA:
      return {
        ...state,
        ...action.payload,
        [EProfileAttributes.IMAGE]: action.payload?.[EProfileAttributes.IMAGE], // TODO: Why was this here?
      };
    default:
      return state;
  }
};

export enum EProfileAttributes {
  IMAGE = 'image',
  PHONE_NUMBER = 'phoneNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  ORGANISATION = 'organisation',
  LOCATION = 'location',
  CONTACT_EMAIL = 'contactEmail',
  LINKEDIN = 'linkedIn',
  ABOUT = 'about',
}

export interface IBaseProfile {
  [EProfileAttributes.IMAGE]?: string;
  [EProfileAttributes.PHONE_NUMBER]?: string;
  [EProfileAttributes.FIRST_NAME]?: string;
  [EProfileAttributes.LAST_NAME]?: string;
  [EProfileAttributes.JOB_TITLE]?: string;
  [EProfileAttributes.ORGANISATION]?: string;
  [EProfileAttributes.LOCATION]?: string;
  [EProfileAttributes.CONTACT_EMAIL]?: string;
  [EProfileAttributes.LINKEDIN]?: string;
  [EProfileAttributes.ABOUT]?: string;
}

export interface ITalentProfile {
  completedProfile: boolean;
  userId?: string;
  companyId?: string;
  baseProfile?: IBaseProfile;
  profile?: { [x in string]: any }; // client based questions
  raisedHands?: string[]; // jobIds
}

export interface IProfileInfo extends ITalentProfile {
  newProfilePic?: string;
}
