import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetUserTalentProfile } from '../../store/actions';
import { RouteMaster } from '../../Components/Routing';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { JobPosts } from '../Jobs/JobPosts';

interface IJob {
  title: string;
  description: string;
}

export const Talent = () => {
  const companyName = useSelector((state: IStoreTypes) => state.user.companyName);
  const hasCompletedProfile = useSelector((state: IStoreTypes) => state.profile?.completedProfile);
  const [gettingProfile, setGettingProfile] = React.useState<boolean>(false);
  const [retrievedProfile, setRetrievedProfile] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const jobs: IJob[] = [
    {
      title: '',
      description: '',
    },
  ];

  React.useEffect(() => {
    setGettingProfile(true);
    BrancherDispatch(dispatch, UtilGetUserTalentProfile(() => {
      setGettingProfile(false);
      setRetrievedProfile(true);
    }));
  }, []);

  return (
    <Page loading={gettingProfile}>
      {!hasCompletedProfile && retrievedProfile && <Redirect to={RouteMaster.editProfile.path} />}
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Text variant="xxl" marginBottom={30} marginTop={30} fontWeight={600} color="purple">
            {companyName} Jobs
          </Text>
        </Grid>
        <JobPosts />
      </Grid>
    </Page>
  );
};
