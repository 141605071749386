import * as React from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RouteMaster, Routes } from './Components/Routing/';
import { HeaderNav } from './Components/HeaderNav/HeaderNav';
import { IStoreTypes } from './store/storeTypes';

const useStyles = makeStyles({
  main: {
    minHeight: (props: { showHeadNav: boolean }) => (!props.showHeadNav ? `100vh` : `calc(100%)`),
  },
});

export const AppMain = () => {
  const shouldShowHeadNav: boolean =
    ![
      RouteMaster.login.path, RouteMaster.signup.path, RouteMaster.splash.path, RouteMaster.forgotPassword.path,
      RouteMaster.verifyForgotPassword.path, RouteMaster.confirmRegistration.path,
    ].includes(window.location.pathname);
  const loggedIn = useSelector((state: IStoreTypes) => state.user.loggedIn);
  const validToken = useSelector((state: IStoreTypes) => state.user?.tokenExp) > new Date().getTime()/1000;
  const [showHeadNav, setShowHeadNav] = React.useState(shouldShowHeadNav && loggedIn);
  const styles = useStyles({ showHeadNav });
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      setShowHeadNav(shouldShowHeadNav && loggedIn && validToken);
    });
    return () => {
      unlisten();
    };
  });

  return (
    <>
      {showHeadNav && <HeaderNav />}
      <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.main}>
        <Routes />
      </Grid>
    </>
  );
};
