import * as React from 'react';
import { Home } from '../../Pages/Home/Home';
import { SplashScreen } from '../../Pages/SplashScreen/SplashScreen';
import { SignUp } from '../../Pages/SignUp/SignUp';
import { Login } from '../../Pages/Login/Login';
import { ForgotPassword } from '../../Pages/ForgotPassword/ForgotPassword';
import { ConfirmRegistration } from '../../Pages/ConfirmRegistration/ConfirmRegistration';
import { VerifyForgotPassword } from '../../Pages/ForgotPassword/VerifyForgotPassword';
import { SuperUser } from '../../Pages/SuperUser/SuperUser';
import { EditProfile } from '../../Pages/Profile/EditProfile';
import { Profile } from '../../Pages/Profile/Profile';
import { Jobs } from '../../Pages/Jobs/Jobs';
import { Job } from '../../Pages/Jobs/Job';

export interface IRouteWithSubRoutesProps {
  path: string;
  component: React.ComponentType<any>;
  name: string;
  title?: string;
  paramsPath?: string;
  routes?: string;
  public?: boolean;
  exact?: boolean;
}

export const GetRoute = (routeName: string) => {
  return routeConfig.find((r) => r.name === routeName);
};

export const routeConfig: IRouteWithSubRoutesProps[] = [
  {
    path: '/',
    component: SplashScreen,
    exact: true,
    name: 'splash',
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    name: 'login',
  },
  {
    path: '/signup',
    component: SignUp,
    exact: false,
    name: 'signup',
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    exact: true,
    name: 'forgotPassword',
  },
  {
    path: '/verifyForgotPassword',
    component: VerifyForgotPassword,
    exact: true,
    name: 'verifyForgotPassword',
  },
  {
    path: '/confirmRegistration',
    component: ConfirmRegistration,
    exact: true,
    name: 'confirmRegistration',
  },
  {
    path: '/home',
    component: Home,
    exact: true,
    name: 'home',
  },
  {
    path: '/editProfile',
    component: EditProfile,
    exact: true,
    name: 'editProfile',
    title: 'Edit Profile - Brancher',
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    name: 'profile',
    title: 'Profile - Brancher',
  },
  {
    path: '/jobs',
    component: Jobs,
    exact: true,
    name: 'jobs',
    title: 'Job Posts - Brancher',
  },
  {
    path: '/job',
    component: Job,
    exact: true,
    name: 'job',
    title: 'Job - Brancher',
  },
  {
    path: '/superUser',
    component: SuperUser,
    exact: true,
    name: 'superUser',
    title: 'Super User - Brancher',
  },
];

export const getRoute = (routeName: string): IRouteWithSubRoutesProps =>
  routeConfig.find((r) => r.name === routeName);

export const RouteMaster = {
  home: getRoute('home'),
  login: getRoute('login'),
  signup: getRoute('signup'),
  forgotPassword: getRoute('forgotPassword'),
  verifyForgotPassword: getRoute('verifyForgotPassword'),
  confirmRegistration: getRoute('confirmRegistration'),
  splash: getRoute('splash'),
  editProfile: getRoute('editProfile'),
  profile: getRoute('profile'),
  jobs: getRoute('jobs'),
  job: getRoute('job'),
  superUser: getRoute('superUser'),
};
