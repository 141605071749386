import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest } from './utils/BrancherDispatch';
import { IFormSelectType } from '../../Components/InputFields/BrancherSelect';

export enum EComponentType {
  TOGGLE = 'toggle',
  TEXT = 'text',
  SLIDER = 'slider',
  DROPDOWN = 'dropdown',
}

interface ITalentQuestion {
  componentType: EComponentType;
  name: string;
  readableName: string;
  question: string;
  label?: string; // What is different between label and question?
  description?: string;
  fullWidth?: boolean; // default is true
}

export interface ITalentText extends ITalentQuestion {
  componentType: EComponentType.TEXT;
  rows?: number;
  multiline?: boolean; // default is true
  placeholder?: string; // default is "Please enter..."
  type?: 'text' | 'number';
}

interface ITalentDropDown extends ITalentQuestion {
  componentType: EComponentType.DROPDOWN;
  options: IFormSelectType[];
}

export interface ITalentSlider extends ITalentQuestion {
  componentType: EComponentType.SLIDER;
  defaultValue?: number;
  max?: number;
  min?: number;
  likertScale?: boolean;
  marks?: Array<{ value: number; label: string }>;
}

export type ITalentQuestions = ITalentDropDown | ITalentText | ITalentSlider;

interface ITalentConfiguration {
  configurationId: string;
  companyId: string;
  configuration: ITalentQuestions[];
  active: boolean;
  raisedHands?: string[]; // userIds
}

interface IUtilGetTalentConfiguration extends IAPIResponseObject {
  data: ITalentConfiguration;
}

export const UtilGetTalentConfiguration = (cb: (a: IUtilGetTalentConfiguration) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/configuration',
        params: JSON.stringify({}),
      },
      getState(),
    )
      .then((response: { data: IUtilGetTalentConfiguration }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
