import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { cognitoClientId, signupRedirectUri } from '../../consts/cognito';
import { signUpCompanyId } from '../../consts/cookies';
import { IUtilSignUpUserResponse } from '../../store/actions/UserInfoActions';
import { UtilSSOSignUp } from '../../store/actions';
import { GetSharedResourceLink } from '../../helpers/LinkHelpers';

export enum SSOTypes {
	GOOGLE = 'google',
	OKTA = 'okta',
}

interface ISSOSignUp {
	controlSSOUser: (res: IUtilSignUpUserResponse) => void;
	type: SSOTypes;
}

export const SSOSignUp: React.FC<ISSOSignUp> = ({controlSSOUser, type}) => {
	const [gettingToken, setGettingToken] = React.useState<boolean>(false);
	const params = new URLSearchParams(document.location.search.substring(1));
	const code = params.get('code');
	const companyId = sessionStorage.getItem(signUpCompanyId);
	const dispatch = useDispatch();
	const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

	React.useEffect(() => {
		if(code && !gettingToken) {
			signupGoogle();
		}
	}, [code]);

	const signupGoogle = () => {
		setGettingToken(true);
		dispatch(UtilSSOSignUp(code, companyId,(a) => {
			setGettingToken(false);
			controlSSOUser(a);
		}));
	};

	const epLink: string = type === SSOTypes.GOOGLE ?
		`https://brancher.auth.ap-southeast-2.amazoncognito.com/authorize?response_type=code&client_id=${cognitoClientId}&redirect_uri=${signupRedirectUri}&identity_provider=Google&scope: aws.cognito.signin.user.admin email openid phone profile`
		: `https://brancher.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?identity_provider=Okta-SSO&response_type=code&client_id=${cognitoClientId}&redirect_uri=${signupRedirectUri}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;

	const icon: string = type === SSOTypes.GOOGLE ? 'googleIcon.png' : 'oktaIcon.png';
	const ssoTypeName = type === SSOTypes.GOOGLE ? 'Google' : 'Okta';

	return (
		<BrancherButton onClick={()=>window.location.assign(epLink)} disabled={!companyId}>
			<img height={mobile ? 20 : 30} alt="google-sign-up-logo" src={GetSharedResourceLink(icon)} />
			<Text variant="xs" color="tertiaryGrey" marginLeft={15} fontWeight={600}>
				Sign up with {ssoTypeName}
			</Text>
		</BrancherButton>
	);
};