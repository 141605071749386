import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';

interface IBrancherDivider {
  marginTop?: number;
  marginBottom?: number;
  width?: string | number;
  height?: string | number;
}

const useStyles = makeStyles((theme) =>({
  normalDivider: {
    background: Colors.disabledGrey,
    height: (props: IBrancherDivider) => props.height ?? 1,
    width: (props: IBrancherDivider) => props.width ?? '100%',
    marginTop: (props: IBrancherDivider) => props.marginTop,
    marginBottom: (props: IBrancherDivider) => props.marginBottom,
  },
  secondaryDivider: {
    background: Colors.lightPurple,
    height: 1,
    width: (props: IBrancherDivider) => props.width ?? '100%',
    marginTop: (props: IBrancherDivider) => props.marginTop,
    marginBottom: (props: IBrancherDivider) => props.marginBottom,
  },
  thickDivider: {
    height: 4,
    width: 40,
    background: theme.palette.primary.main,
    marginTop: (props: IBrancherDivider) => props.marginTop,
    marginBottom: (props: IBrancherDivider) => props.marginBottom,
  },
  dottedDivider: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 20%, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: `top`,
    backgroundSize: `10px 2px`,
    backgroundRepeat: `repeat-x`,
    height: 2,
    marginTop: (props: IBrancherDivider) => props.marginTop ,
    marginBottom: (props: IBrancherDivider) => props.marginBottom,
  }
}));

export const BrancherDivider = (props: IBrancherDivider) => {
  const styles = useStyles(props);
  return <Divider className={styles.normalDivider} />;
};

export const SecondaryDivider = (props: IBrancherDivider) => {
  const styles = useStyles(props);
  return <Divider className={styles.secondaryDivider} />;
};

export const BrancherThickDivider = (props: IBrancherDivider) => {
  const styles = useStyles(props);
  return <div className={styles.thickDivider} />;
};

export const BrancherDottedDivider = (props: IBrancherDivider) => {
  const styles = useStyles(props);
  return <div className={styles.dottedDivider} />;
};
