import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetRoute } from '../../Components/Routing';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { Text } from '../../Components/General/Text';
import { SaveUserInfo, UtilSignUp, UtilGetCompanyInfo, UtilCheckUserExists } from '../../store/actions';
import { IUtilSignUpUserResponse } from '../../store/actions/UserInfoActions';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';
import { BackSplashImagery } from '../Login/BackSplashImagery';
import { BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { validatePhoneNumber } from '../../utils/validators/validatePhoneNumber';
import { defaultPasswordRules, PasswordValidation } from '../../Components/General/PasswordValidation';
import { IValidationRules, simpleValidatePassword } from '../../utils/validators/validatePassword';
import { signUpCompanyId } from '../../consts/cookies';
import { SSOSignUp, SSOTypes } from './SSOSignUp';
import { signupRedirectUri } from '../../consts/cognito';
import { IStoreTypes } from '../../store/storeTypes';
import { validateEmail } from '../../utils/validators/validateEmail';
import { BrancherTnCs } from './BrancherTnCs';
import { SAMLSignUp } from './SAMLSignUp';
import { BrancherPhoneNumberField } from '../../Components/InputFields/BrancherPhoneNumberField';

export const SignUp = () => {
  const googleAuth = useSelector((state: IStoreTypes) => state.user?.googleSignOn);
  const [fName, setFName] = React.useState<string>('');
  const [lName, setLName] = React.useState<string>('');
  const [organisation, setOrganisation] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [agreeToPolicies, setAgreeToPolicies] = React.useState<boolean>(false);
  const [signedUp, hasSignedUp] = React.useState<boolean>(false);
  const [waiting, updateWaiting] = React.useState<boolean>(false);
  const [validatorAnchor, setValidatorAnchor] = React.useState(null);
  const [fieldErrors, setFieldErrors] = React.useState<string[]>([]);
  const [apiError, setApiError] = React.useState<string>('');
  const [userId, setUserId] = React.useState<string>('');
  const [passwordPolicy, setPasswordPolicy] = React.useState<IValidationRules>(defaultPasswordRules);
  const [isPreviousUser, setIsPreviousUser] = React.useState<boolean>(false);
  const [checkedEmail, setCheckedEmail] = React.useState<boolean>(false);
  const [simpleJoin, setSimpleJoin] = React.useState<boolean>(true);
  const [isSSO, setIsSSO] = React.useState<boolean>(false);
  const [isSAMLSSO, setIsSAMLSSO] = React.useState<boolean>(false);
  const [samlRedirectUri, setSAMLRedirectUri] = React.useState<string>('');
  const [redirectToVerifyConfirmation, setRedirectToVerifyConfirmation] = React.useState(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const params = new URLSearchParams(document.location.search.substring(1));
  const companyId = params.get('cId') || sessionStorage.getItem(signUpCompanyId);
  if(!!companyId) {
    sessionStorage.setItem(signUpCompanyId, companyId);
  }

  React.useEffect(() => {
    if (companyId && !organisation) {
      retrieveCompanyName()
    }
  }, []);

  const retrieveCompanyName = () => {
    dispatch(UtilGetCompanyInfo(companyId, (response) => {
      if (response.success) {
        dispatch(SaveUserInfo({ companyName: response.companyName, googleSignOn: response?.googleSignOn, samlSignOn: response?.samlSignOn }));
        setIsSAMLSSO(response?.samlSignOn);
        setIsSSO(response?.samlSignOn ?? isSSO);
        setSAMLRedirectUri(response?.samlUri);
        setPasswordPolicy(response?.passwordValidation);
        setOrganisation(response.companyName);
      }
    }));
  };

  const signUp = () => {
    const errors: string[] = validateFields();
    if (errors.length === 0) {
      updateWaiting(true);
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(UtilSignUp(email, password, companyId, fName, lName, phoneNumber, userId, isSSO,(response) => {
        if (response.success) {
          dispatch(SaveUserInfo({ firstName: fName, lastName: lName, username: email, email }));
          setIsPreviousUser(response?.message === 'ALREADY_SIGNED_UP');
          hasSignedUp(true);
        } else {
          dispatch(SaveUserInfo({ username: email }));
          if (response.message === 'Verify email address') {
            setRedirectToVerifyConfirmation(true);
          }
          setApiError(response.message);
          updateWaiting(false);
        }
      }));
    } else {
      setFieldErrors(errors);
    }
  };

  const controlSSOUser = (res: IUtilSignUpUserResponse) => {
    const { message, data, success } = res;
    window.history.replaceState({}, document.title, `${signupRedirectUri}`);
    retrieveCompanyName();
    if(!success) {
      setApiError(message);
    } else {
      setEmail(data.email);
      setIsSSO(true);
      setSimpleJoin(data.userExists);
      if(data.firstName && data.lastName) {
        setFName(data.firstName);
        setLName(data.lastName);
      } else {
        setIsPreviousUser(data.userExists);
        setUserId(data.userId);
        setAgreeToPolicies(data.agreeToPolicies);
        setPhoneNumber(data.phoneNumber);
      }
    }
  };

  const emailOnBlur = () => {
    if(email !== '') {
      updateWaiting(true);
      dispatch(UtilCheckUserExists(email, (userExists) => {
        setIsSAMLSSO(userExists.data?.samlSignOn)
        setSAMLRedirectUri(userExists.data?.samlUri)
        setSimpleJoin(userExists?.data?.userExists);
        setOrganisation(userExists?.data?.companyName);
        updateWaiting(false);
        setCheckedEmail(true);
      }));
    }
  };

  // Checks that all the values are filled
  const validateFields = (): string[] => {
    const errors: string[] = [];
    if (fName === '') {
      errors.push('fName');
    }
    if (lName === '') {
      errors.push('lName');
    }
    if (organisation === '') {
      errors.push('organisation');
    }
    if (email === '' || !validateEmail(email)) {
      errors.push('email');
    }
    if(!isSSO) {
      if (password === '' || !simpleValidatePassword(password)) {
        errors.push('password');
        setValidatorAnchor(document.getElementById('password'));
      }
    }
    if (phoneNumber === '' || !validatePhoneNumber(phoneNumber)) {
      errors.push('phoneNumber');
    }
    return errors;
  };

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(validatorAnchor)) {
      setValidatorAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(validatorAnchor)) {
      setValidatorAnchor(null);
    }
  };

  const simpleJoinValidation = (): boolean => {
    return (Boolean(organisation) && Boolean(email));
  };

  const isDisabled = (): boolean => {
    return !((simpleJoin && checkedEmail && simpleJoinValidation()) || (!simpleJoin && agreeToPolicies));
  };

  return (
    <BackSplashImagery>
      <Grid container direction="row" alignItems="center" justifyContent="center" onClick={closeValidator}>
        {redirectToVerifyConfirmation && <Redirect to={GetRoute('confirmRegistration').path} />}
        <Grid item xs={12}>
          <Grid container direction="column" justifyContent="center">
            <Text variant="xl" fontWeight={600}>
              Set up an account
            </Text>
            <BrancherDottedDivider marginTop={40} marginBottom={30} />
            {apiError && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
                {apiError}
              </Text>
            )}
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item xs={12}>
                <BrancherTextField
                  fullWidth
                  autoCapitalize="off"
                  name="email"
                  type="email"
                  value={email}
                  updateValue={setEmail}
                  placeholder="Your Email Address"
                  label="Email Address"
                  required
                  onBlur={emailOnBlur}
                  error={fieldErrors.includes('email')}
                  helperText={fieldErrors.includes('email') && 'Please enter a valid email!'}
                />
              </Grid>
              {!(simpleJoin || isSSO) && (
                <Grid item xs={12}>
                  <BrancherTextField
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="off"
                    updateValue={setPassword}
                    placeholder="Enter Password"
                    label="Password"
                    required
                    onClick={controlValidator}
                    error={fieldErrors.includes('password')}
                    helperText={fieldErrors.includes('password') && 'Please enter a stronger password!'}
                  />
                  <PasswordValidation anchorEl={validatorAnchor} password={password} passwordValidationRules={passwordPolicy} />
                </Grid>
              )}
              <Grid item xs={12}>
                <BrancherTextField
                  inputProps={{ readOnly: true }}
                  fullWidth
                  name="organisation"
                  value={organisation}
                  updateValue={setOrganisation}
                  placeholder="Your Organisation Name"
                  label="Organisation"
                  required
                  error={fieldErrors.includes('organisation')}
                  helperText={
                    fieldErrors.includes('organisation') && 'Please check the link your organisation sent you!'
                  }
                />
              </Grid>
              {!simpleJoin && (
                <>
                  <Grid item xs={5}>
                    <BrancherTextField
                      fullWidth
                      value={fName}
                      name="firstName"
                      updateValue={setFName}
                      placeholder="First Name"
                      label="First Name"
                      required
                      error={fieldErrors.includes('fName')}
                      helperText={fieldErrors.includes('fName') && 'Please enter a valid first name!'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <BrancherTextField
                      fullWidth
                      value={lName}
                      name="lastName"
                      updateValue={setLName}
                      placeholder="Last Name"
                      label="Last Name"
                      required
                      error={fieldErrors.includes('lName')}
                      helperText={fieldErrors.includes('lName') && 'Please enter a valid last name!'}
                    />
                  </Grid>
                </>
              )}
              {!simpleJoin && (
                <Grid item xs={12}>
                  <BrancherPhoneNumberField
                    fullWidth
                    name="phoneNumber"
                    value={phoneNumber}
                    updateValue={setPhoneNumber}
                    placeholder="0412345678"
                    label="Phone Number"
                    required
                    error={fieldErrors.includes('phoneNumber')}
                    helperText={fieldErrors.includes('phoneNumber') && 'Please enter a valid mobile phone number!'}
                  />
                </Grid>
              )}
              {!simpleJoin && (
                <Grid item xs={12}>
                  <Box marginTop={3} />
                  <BrancherTnCs value={agreeToPolicies} setValue={setAgreeToPolicies} />
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Box marginTop={4} alignSelf="center">
                <NextButton onClick={signUp} disabled={isDisabled()} fullWidth>
                  {waiting && !signedUp ? (
                    <CircularProgress color="primary" size={30} />
                  ) : signedUp && (isPreviousUser || isSSO) ? (
                    <Redirect to={GetRoute('login').path} />
                  ) : signedUp && !isSSO ? (
                    <Redirect to={GetRoute('confirmRegistration').path} />
                  ) : (
                    'Submit'
                  )}
                </NextButton>
              </Box>
            </Grid>
            {googleAuth && (
              <Grid item container justifyContent="center">
                <Box marginTop={mobile ? 2 : 4} marginBottom={mobile ? 2 : 3}>
                  <SSOSignUp controlSSOUser={controlSSOUser} type={SSOTypes.GOOGLE} />
                </Box>
              </Grid>
            )}
            {isSAMLSSO && samlRedirectUri && (
              <Grid item container justifyContent="center">
                <Box marginBottom={mobile ? 2 : 3} marginTop={3}>
                  <SAMLSignUp
                    samlRedirectUri={samlRedirectUri}
                    controlSSOUser={controlSSOUser}
                  />
                </Box>
              </Grid>
            )}
            <Box display="inline" textAlign="center" mt={2}>
              <Text variant="sm" display="inline">
                Already have an account?{' '}
              </Text>
              <BrancherRouterLink to={GetRoute('login').path}>
                <Text variant="sm" color="purple" display="inline" textDecoration="underline">
                  Login
                </Text>
              </BrancherRouterLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
