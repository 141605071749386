import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SvgIcon from '@material-ui/core/SvgIcon';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Colors } from '../../consts/colors';
import { mainNavWidth, mobileMainNavHeight } from '../../consts/DimensionConsts';
import { GetRoute, RouteMaster } from '../Routing';
import { Text } from '../General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { LogoutButton } from '../InputFields/BrancherButton';
import { BrancherDispatch, UtilSignOut } from '../../store/actions';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { GetIllustrationLink, GetLinkBase } from '../../helpers/LinkHelpers';
import { EProfileAttributes } from '../../store/reducers/ProfileReducer';
import { Dashboard } from '@material-ui/icons';

interface IHeaderNavStyles {
  mobile: boolean;
  navLinkAmount: number;
  hasLogo: boolean;
}

const useStyles = makeStyles({
  navBar: {
    height: (props: IHeaderNavStyles) => (props.mobile ? mobileMainNavHeight : '100vh'),
    width: (props: IHeaderNavStyles) => (props.mobile ? '100vw' : mainNavWidth),
    left: (props: IHeaderNavStyles) => (props.mobile ? 'auto' : 0),
    bottom: (props: IHeaderNavStyles) => (props.mobile ? 0 : 'auto'),
    top: (props:IHeaderNavStyles) => props.mobile && 'auto',
    overflowY: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
    padding: `0px !important`,
  },
  selected: {
    marginLeft: 10,
  },
  unselected: {
    marginLeft: 10,
  },
  navLinkSection: {
    minHeight: (props: IHeaderNavStyles) => (props.mobile ? 55 : 'auto'),
    height: (props: IHeaderNavStyles) => (props.mobile ? 55 : 'auto'),
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileNavLinkSection: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
    width: (props: IHeaderNavStyles) => `calc(100% / ${props.navLinkAmount})`,
  },
  navSectionSelected: {
    background: Colors.white,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
  },
  mobileNavSectionSelected: {
    background: Colors.white,
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  mobileNavSectionUnselected: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  navSection: {
    minHeight: 72,
    maxHeight: 72,
  },
  profilePic: {
    maxWidth: 100,
    minWidth: 100,
    maxHeight: 100,
    minHeight: 100,
    borderRadius: '50%',
  },
  profilePicBacking: {
    marginTop: 50,
    borderRadius: '50%',
    maxWidth: 106,
    minWidth: 106,
    maxHeight: 106,
    minHeight: 106,
    background: Colors.white,
  },
  navHeader: {
    marginBottom: 30,
  },
  navIcon: {
    fontSize: 30,
  },
  logo: {
    marginTop: 40,
    maxHeight: 90,
    width: 90,
    height: 'auto',
  },
});

interface INavConfig {
  isCurrentRoute: boolean;
  isVisible: boolean;
  route: string;
  icon: React.ReactNode;
  title: string;
}

export const HeaderNav = () => {
  const selectedRoute = useLocation().pathname;
  const firstName = useSelector((state: IStoreTypes) => state.user.firstName);
  const loggedIn = useSelector((state: IStoreTypes) => state.user.loggedIn);
  const customLogo = useSelector((state: IStoreTypes) => state.user?.customLogo);
  const companyName = useSelector((state: IStoreTypes) => state.user.companyName);
  const profileImage = useSelector((state: IStoreTypes) => state?.profile?.baseProfile?.image);
  const profileFirstName = useSelector((state: IStoreTypes) => state?.profile?.baseProfile?.[EProfileAttributes.FIRST_NAME]);
  const validToken = useSelector((state: IStoreTypes) => state.user?.tokenExp) > new Date().getTime()/1000;
  const userPositions = useSelector((state: IStoreTypes) => state.user?.positions);
  const hasCompletedProfile = useSelector((state: IStoreTypes) => state.profile?.completedProfile);
  const isBrancherAdmin = userPositions?.includes(AllPositions.BRANCHER_ADMIN);
  const isTalentAdmin = userPositions?.includes(AllPositions.TALENT_ADMIN);
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const dispatch = useDispatch();
  const hasLogo = !!customLogo;

  const {
    home, superUser, jobs
  } = RouteMaster;

  const isHomePath = selectedRoute === home.path;
  const isJobPostsPath = selectedRoute === jobs.path;
  const isSuperUserPath = selectedRoute === superUser.path;

  const navConfig: INavConfig[] = [
    {
      route: home.path,
      isCurrentRoute: isHomePath,
      icon: <Dashboard color={isHomePath ? "primary" : "secondary"} />,
      title: 'Dashboard',
      isVisible: loggedIn && validToken,
    },
    {
      route: jobs.path,
      isCurrentRoute: isJobPostsPath,
      icon: <EmojiPeople color={isJobPostsPath ? "primary" : "secondary"} />,
      title: 'Job posts',
      isVisible: isTalentAdmin,
    },
    // {
    //   route: superUser.path,
    //   isCurrentRoute: isSuperUserPath,
    //   icon: <SupervisorAccount color={isSuperUserPath ? "primary" : "secondary"} />,
    //   title: 'Super User',
    //   isVisible: isBrancherAdmin,
    // },
  ];
  const navLinkAmount = navConfig.filter((c) => c.isVisible).length;
  const styles = useStyles({ mobile, navLinkAmount, hasLogo });

  const config = [];
  if (!mobile && validToken) {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.navLinkSection} key={index}>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item container xs={1} />
              <Grid
                item
                container
                xs={11}
                justifyContent="center"
                alignItems="center"
                className={cx(styles.navSection, {
                  [styles.navSectionSelected]: nav.isCurrentRoute,
                })}
              >
                <Grid item xs={4}>
                  <SvgIcon
                    className={cx(
                      styles.navIcon,
                      nav.isCurrentRoute ? styles.selected : styles.unselected,
                    )}
                  >
                    {nav.icon}
                  </SvgIcon>
                </Grid>
                <Grid item xs={6}>
                  <Text
                    variant="xs"
                    fontWeight={nav.isCurrentRoute ? 600 : 500}
                    color={nav.isCurrentRoute ? 'purple' : 'white'}
                    noPreWrap
                  >
                    {nav.title}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Link>,
        );
      }
    });
  } else {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.mobileNavLinkSection} key={index}>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={cx(styles.mobileNavSectionUnselected, {
                [styles.mobileNavSectionSelected]: nav.isCurrentRoute,
              })}
            >
              <SvgIcon
                className={cx(
                  styles.navIcon,
                  nav.isCurrentRoute ? styles.selected : styles.unselected,
                )}
              >
                {nav.icon}
              </SvgIcon>
            </Grid>
          </Link>,
        );
      }
    });
  }

  return (
    <AppBar className={styles.navBar} position="fixed">
      <Grid container alignItems="center" item>
        {!mobile && (
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.navHeader}
          >
            {hasLogo && (
              <Grid item container justifyContent="center">
                <img src={`${GetLinkBase()}${customLogo}`} alt={`${companyName}-logo`} className={styles.logo} />
              </Grid>
            )}
            <Link to={GetRoute('editProfile').path}>
              <Grid
                item
                xs={12}
                className={styles.profilePicBacking}
                container
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={profileImage ? `${GetLinkBase()}${profileImage}` : GetIllustrationLink('noAvatar.png')}
                  className={styles.profilePic}
                  alt="profile"
                />
              </Grid>
            </Link>
            <Grid item>
              <Text variant="xs" color="white" fontWeight={400} marginTop={20}>
                Welcome back,
              </Text>
              <Text variant="lg" fontWeight={600} color="white" align="center">
                {profileFirstName ?? firstName}
              </Text>
            </Grid>
            <Grid item container xs={8} justifyContent="center">
              <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center" direction={mobile ? 'row' : 'column'} alignItems="center" item>
          {config}
          {mobile && (
            <Grid item container xs={11} justifyContent="flex-end">
              <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};
