import { ICompanyInfo, IUserInfo, SAVE_USER_INFO } from '../reducers/UserInfoReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAPIKeyRequest, BrancherAuthRequest, isLocalEnvironment } from './utils/BrancherDispatch';
import { AllPositions, BrancherPlatformType } from '../../consts/ProgramPositionOptions';
import { IValidationRules } from '../../utils/validators/validatePassword';

export const SaveUserInfo = (userData: IUserInfo) => {
  return {
    type: SAVE_USER_INFO,
    payload: userData,
  };
};

export const SignUserOut = () => {
  return {
    type: 'USER_LOGOUT',
  };
};

export const SetUserRedirect = (userData: boolean) => {
  return {
    type: 'USER_REDIRECT',
    payload: userData,
  };
};

// This does a fire and forget on signing the user out
export const UtilSignOut = () => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'signout',
        data: {
          username,
        },
      },
      getState(),
    ).then(() => {
      dispatch(SignUserOut());
    });
  };
};

export interface IUtilLoginResponse {
  data: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    companyId: string;
    companyName: string;
    userSub: string;
    loggedInPlatform: BrancherPlatformType;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    positions?: AllPositions[];
    tokenExp?: number;
    passwordSignOn?: boolean;
    googleSignOn?: boolean;
    whiteLabel?: boolean;
    customLogo?: string;
  };
}

export enum EMFAChallengeNames {
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA= 'SOFTWARE_TOKEN_MFA',
}

interface IMFALoginResponse {
  isMFA: boolean;
  challengeName: EMFAChallengeNames;
  session: string;
  keyCode?: string; // only if it's the initial MFA_SETUP
}

export interface IUtilLoginUserResponse extends IAPIResponseObject {
  data: IUtilLoginResponse['data'] & IMFALoginResponse; // TODO: Refactor this out properly
}

// This logs the user in
export const UtilLogin = (username: string, password: string, cb: (a: IUtilLoginUserResponse) => void) => {
  return (dispatch: any) => {
    dispatch(SignUserOut());
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/login',
      data: {
        username,
        pwd: password,
        loggedInPlatform: BrancherPlatformType.talent,
        lastLoggedIn: new Date().getTime(),
      },
    }).then((response: any) => {
      cb(response.data);
    }).catch((error) => {
      cb(error);
    });
  };
};


// This verifies the user TOTP setup on initial login
export const UtilMFASetupVerification = (
  validationCode: string,
  accessToken: string,
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/mfa/verify',
      data: {
        validationCode,
        accessToken,
      },
    }).then((response: any) => {
      cb(response.data);
    }).catch((error) => {
      cb(error);
    });
  };
};

// This verifies the user TOTP code to sign in
export const UtilMFASessionVerification = (
  validationCode: string,
  session: string,
  username: string,
  programId: string = '',
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/mfa/challenge',
      data: {
        validationCode,
        session,
        username,
        programId,
        loggedInPlatform: BrancherPlatformType.talent
      },
    }).then((response: any) => {
      cb(response.data);
    }).catch((error) => {
      cb(error);
    });
  };
};

// This signs the user up
export const UtilSignUp = (
  username: string,
  password: string = '',
  companyId: string = '',
  fName: string,
  lName: string,
  phoneNumber: string,
  userId: string,
  isSSO: boolean,
  cb: (a: IAPIResponseObject) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'talent/signup',
      data: {
        username,
        email: username,
        pwd: password,
        companyId,
        firstName: fName,
        lastName: lName,
        phoneNumber: phoneNumber.replaceAll(/ /g, '').replaceAll(/-/g, ''),
        userId,
        isSSO,
        agreeToPolicies: true,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This confirms the user's registration with a verification code
export const UtilConfirmRegistration = (
  username: string,
  verification: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'confirmregistration',
      data: {
        username,
        verification,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This initialises a verification code for the user when resetting a password
export const UtilInitialiseForgotPassword = (
  username: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any) => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'forgotpassword',
      data: {
        username,
      },
    })
      .then((response: any) => {
        dispatch(SaveUserInfo({ forgotPasswordEmail: response.data?.data?.username, passwordPolicy: response.data?.data?.passwordPolicy }));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This updates the password after the user has verified their email in InitialiseForgotPassword
export const UtilUpdatePassword = (
  username: string,
  newPassword: string,
  verification: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'confirmpassword',
      data: {
        username,
        pwd: newPassword,
        verification,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IGetCompanyInfo extends IAPIResponseObject {
  companyName: string;
  googleSignOn?: boolean;
  samlSignOn?: boolean;
  samlUri?: string;
  passwordValidation?: IValidationRules;
}

export const UtilGetCompanyInfo = (companyId: string, cb: (a: IGetCompanyInfo) => void) => {
  return () => {
    const env = isLocalEnvironment() ? 'local' : window.location.hostname.split('.')[0];
    BrancherAPIKeyRequest({
      method: 'get',
      url: 'v2/company',
      params: JSON.stringify({
        companyId,
        env
      }),
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This sets the deactivate flag for a user
export const UtilSetUserDeactivation = (userId: string, deactivate: boolean, cb: (a?: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/userdeactivation',
        data: {
          userId,
          programId,
          deactivate,
        },
      },
      getState(),
    )
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This verifies the sso auth code for login
export const UtilSSOLogin = (code: string, cb: (a: IUtilLoginResponse) => void) => {
  return () => {
    const env = isLocalEnvironment() ? 'local' : window.location.hostname.split('.')[0];
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssologin',
      data: {
        code,
        env,
        loggedInPlatform: BrancherPlatformType.talent
      },
    }).then((res) => {
      cb(res.data);
    }).catch((error) => {
      cb(error);
    });
  };
};

export interface IUtilSignUpUserResponse extends IAPIResponseObject {
  data: {
    email: string;
    programId: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    userExists?: boolean;
    emailVerified?: boolean;
    agreeToPolicies?: boolean;
  };
}

// This verifies the sso auth code for signup
export const UtilSSOSignUp = (code: string, signUpCompanyId: string, cb: (a: IUtilSignUpUserResponse) => void) => {
  return () => {
    const env = isLocalEnvironment() ? 'local' : window.location.hostname.split('.')[0];
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssosignup',
      data: {
        code,
        signUpCompanyId,
        env,
      },
    }).then((res) => {
      cb(res.data);
    }).catch((error) => {
      cb(error);
    });
  };
};

interface ICheckCompanyUserExists extends Omit<ICompanyInfo, 'companyId'> {
  userExists: boolean;
  samlUri?: string;
}

interface ICheckUserExists extends IAPIResponseObject {
  data: ICheckCompanyUserExists;
}

// TODO: Potentially remove this, enumeration attack as it's unauthenticated endpoint
// Note: 8/12/2022 - Accepting the risk on the signup endpoint for now
// This checks whether the user exists and retrieves initial company information
export const UtilCheckUserExists = (username: string, cb: (a: ICheckUserExists) => void) => {
  return (dispatch: any) => {
    BrancherAPIKeyRequest({
      method: 'get',
      url: 'talent/companyuserdomain',
      params: JSON.stringify({
        email: username,
      })
    }).then((response: any) => {
      dispatch(SaveUserInfo(response.data.data));
      cb(response.data);
    }).catch((error) => {
      cb(error);
    });
  };
};


interface IUtilSSOValidateSAML extends IAPIResponseObject {
  data: {redirectUri: string;};
}

// This get the SAML redirect URI configuration for the user
export const UtilSSOValidateSAML = (username: string, cb: (a: IUtilSSOValidateSAML) => void) => {
  return () => {
    const env = isLocalEnvironment() ? 'local' : window.location.hostname.split('.')[0];
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssovalidatesaml',
      data: {
        username,
        env,
      },
    }).then((res) => {
      cb(res.data);
    }).catch((error) => {
      cb(error);
    });
  };
};


interface IUtilSSOSAMLCompatible extends IAPIResponseObject {
  data: {compatible: boolean;};
}

// This checks if the current user can SSO SAML login
export const UtilSSOSAMLCompatible = (username: string, cb: (a: IUtilSSOSAMLCompatible) => void) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'get',
      url: 'v2/samlcompatible',
      params: JSON.stringify({
        username,
      }),
    }).then((res) => {
      cb(res.data);
    }).catch((error) => {
      cb(error);
    });
  };
};

// This gets the SAML user from the token given back from the authorised third party
export const UtilSSOSAMLLogin = (token: string, cb: (a: IUtilLoginUserResponse) => void) => {
  return (dispatch: any, getState: any) => {
    const isLocal = isLocalEnvironment();
    let location = window.location.hostname.split('.')[0];
    const currState = getState();
    BrancherAuthRequest({
      method: 'post',
      url: 'v2/ssosamllogin',
      data: {
        lastLoggedIn: (new Date().getTime()),
        loggedInPlatform: isLocal ? BrancherPlatformType.talent : location,
      },
    }, {...currState, user: {...currState.user, IDToken: token}})
      .then((res) => {
        cb(res.data);
      }).catch((error) => {
      cb(error);
    });
  };
};

// This signs up the user after a successful SAML response
export const UtilSSOSAMLSignUp = (token: string, cb: (a: IUtilSignUpUserResponse) => void) => {
  return (dispatch: any, getState: any) => {
    const currState = getState();
    const env = isLocalEnvironment() ? 'local' : window.location.hostname.split('.')[0];;
    BrancherAuthRequest({
      method: 'post',
      url: 'v2/samlssosignup',
      data: {
        env,
      },
    }, {...currState, user: {...currState.user, IDToken: token}})
      .then((res) => {
        cb(res.data);
      }).catch((error) => {
      cb(error);
    });
  };
};
