import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetTalentJobPosts } from '../../store/actions';
import { IJobPost } from '../../store/actions/JobPostActions';
import { Colors } from '../../consts/colors';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { CreateJobPost } from './CreateJobPost';

const useStyles = makeStyles((theme) => ({
  margins: {
    marginTop: 60,
  },
  jobCard: {
    padding: 15,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: `2px solid ${Colors.blue}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 200,
    height: 200,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  createJob: {
    marginTop: 60,
    marginBottom: 30,
    marginLeft: 15,
    padding: 10,
    minHeight: 120,
    minWidth: 250,
    maxWidth: 400,
    borderRadius: 12,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    border: `1px solid ${Colors.backgroundGrey}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
}));

export const JobPosts: React.FC = () => {
  const [gettingJobs, setGettingJobs] = React.useState<boolean>(false);
  const [jobs, setJobs] = React.useState<IJobPost[]>([]);
  const [openCreateJob, setOpenCreateJob] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    setGettingJobs(true);
    BrancherDispatch(dispatch, UtilGetTalentJobPosts((jobPosts) => {
      setJobs(jobPosts.data)
      setGettingJobs(false);
    }));
  }, []);

  const showJob = (jobId: string) => {
    window.location.assign(`/job?j=${jobId}`);
  }

  return (
    <Page loading={gettingJobs}>
      <BrancherDialog setClose={() => setOpenCreateJob(false)} title="Create Job Post" labelledBy="Create Job Post" open={openCreateJob}>
        <CreateJobPost setClose={(job) => {setOpenCreateJob(false); setJobs([...jobs,job]);}} />
      </BrancherDialog>
      <Grid container justifyContent="center">
        <Grid container spacing={4} item xs={10}>
          {jobs?.map((job) => (
            <Grid item xs={6} md={4} key={job.jobId}>
              <Box className={styles.jobCard} onClick={() => showJob(job.jobId)}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                  <Grid item>
                    <Text variant="md" marginBottom={20} align="center">{job.title}</Text>
                  </Grid>
                  <Grid item>
                    <Text variant="sm" align="center">{job.description}</Text>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={10}>
          <Grid item container className={styles.createJob} alignItems="center" justifyContent="center" onClick={() => setOpenCreateJob(!openCreateJob)}>
            <Add fontSize="large" />
            <Text variant="md" color="purple" fontWeight={600} marginLeft={20}>
              Create job
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
