import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Page } from '../../Components/General/Page';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { RouteMaster } from '../../Components/Routing';
import { AllPositions } from '../../consts/ProgramPositionOptions';

const useStyles = makeStyles((theme) => ({
	margins: {
		marginTop: 60,
	},
}));

export const SuperUser = () => {
	const isSuperUser = useSelector((state: IStoreTypes) => state.user?.positions).includes(AllPositions.BRANCHER_ADMIN);
	const styles = useStyles();

	return (
		<Page>
			{!isSuperUser && <Redirect to={RouteMaster.home.path} />}
			<Grid container justifyContent="space-around" alignItems="center" className={styles.margins} spacing={2}>
				<Grid container justifyContent="center" item>
					<Text variant="xl" marginBottom={30} color="purple">
						All Talent Organisations
					</Text>
				</Grid>
			</Grid>
		</Page>
	);
};
