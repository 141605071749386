import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiStepLabel = (theme: IBrancherThemePalette) => ({
  label: {
    fontSize: '18px',
    color: Colors.black,
    fontWeight: 600,
    '&.MuiStepLabel-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
});
