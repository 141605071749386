import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { IStoreTypes } from '../../store/storeTypes';
import { Colors } from '../../consts/colors';
import { EProfileAttributes, ITalentProfile } from '../../store/reducers/ProfileReducer';
import {
  BrancherDispatch,
  UtilSaveTalentProfile,
  UtilSetupTalentProfile,
  SaveProfileData,
  UtilGetUserTalentProfile
} from '../../store/actions';
import { mobileMainNavHeight } from '../../consts/DimensionConsts';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';
import { Page } from '../../Components/General/Page';
import { EditTalentProfile } from './EditTalentProfile';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: (props: { mobile: boolean }) => props.mobile ? `calc(${mobileMainNavHeight} + 120px)` : 20,
  },
  fixedActionPane: {
    marginBottom: (props: { mobile: boolean }) => (props.mobile ? mobileMainNavHeight : 0),
    position: 'fixed',
    padding: `10px 0px 10px`,
    bottom: 0,
    marginLeft: -30,
    height: 100,
    backgroundColor: Colors.backgroundLightPurple,
    width: '-webkit-fill-available',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
  paneButtonContainer: {
    width: '100%',
    marginTop: 8,
    '& > .MuiButton-root': {
      marginRight: 60,
    },
  },
});

export const EditProfile: React.FC = () => {
  const profileData = useSelector((state: IStoreTypes) => state.profile);
  const { firstName, lastName, email, phoneNumber, companyId, id: userId } = useSelector((state: IStoreTypes) => state.user);
  const [profile, setProfile] = React.useState<ITalentProfile>({
    ...profileData,
    baseProfile: {
      ...profileData.baseProfile,
      [EProfileAttributes.FIRST_NAME]: profileData?.[EProfileAttributes.FIRST_NAME] ?? firstName,
      [EProfileAttributes.LAST_NAME]: profileData?.[EProfileAttributes.LAST_NAME] ?? lastName,
      [EProfileAttributes.CONTACT_EMAIL]: profileData[EProfileAttributes.CONTACT_EMAIL] ?? email,
      [EProfileAttributes.PHONE_NUMBER]: profileData?.[EProfileAttributes.PHONE_NUMBER] ?? phoneNumber,
      [EProfileAttributes.LOCATION]:
        profileData?.[EProfileAttributes.LOCATION] ?? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    },
  });
  const [saving, setSaving] = React.useState<boolean>(false);
  const [gettingProfile, setGettingProfile] = React.useState<boolean>(false);
  const [validations, setValidations] = React.useState<string[]>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });

  React.useEffect(() => {
    setGettingProfile(true);
    if (!profile?.completedProfile) {
      BrancherDispatch(dispatch, UtilSetupTalentProfile((profileRes) => {
        setProfile({
          ...profile,
          baseProfile: profileRes,
        });
        setGettingProfile(false);
      }));
    } else {
      BrancherDispatch(dispatch, UtilGetUserTalentProfile((profileRes) => {
        setProfile({
          baseProfile: profileRes.baseProfile,
          profile: profileRes.profile,
          completedProfile: profileRes.completedProfile,
        });
        setGettingProfile(false);
      }));
    }
  }, []);

  const returnMissingFields = (): string[] => {
    const fields: string[] = [];
    Object.keys(EProfileAttributes).forEach((attribute) => {
      if (!Boolean(profile?.baseProfile?.[EProfileAttributes[attribute]])) {
        fields.push(EProfileAttributes[attribute]);
      }
    });
    return fields;
  };

  const validateAllData = (): boolean => {
    return Boolean(
      profile?.baseProfile?.[EProfileAttributes.CONTACT_EMAIL] &&
        profile?.baseProfile?.[EProfileAttributes.FIRST_NAME] &&
        profile?.baseProfile?.[EProfileAttributes.LAST_NAME] &&
        profile?.baseProfile?.[EProfileAttributes.LOCATION] &&
        profile?.baseProfile?.[EProfileAttributes.ORGANISATION] &&
        profile?.baseProfile?.[EProfileAttributes.JOB_TITLE] &&
        profile?.baseProfile?.[EProfileAttributes.PHONE_NUMBER],
    );
  };

  const saveProfile = () => {
    if (validateAllData()) {
      setValidations([]);
      setSaving(true);
      BrancherDispatch(dispatch, UtilSaveTalentProfile(profile, true,() => {
        setSaving(false);
        setOpenSnackbar(true);
      }));
    } else {
      setValidations(returnMissingFields());
    }
  };

  const saveBaseProfileData = (attribute: string, val: string | string[]) => {
    setProfile({ ...profile, baseProfile: {...profile.baseProfile, [attribute]: val }});
  };

  const saveTalentProfileData = (attribute: string, val: string | string[]) => {
    setProfile({ ...profile, profile: {...profile.profile, [attribute]: val }});
  };

  const createFileName = (newProfilePic: string): string => {
    const imageFileType1 = newProfilePic.split(':image/');
    const imageFileType2 = imageFileType1[1].split(';base64');
    return `${companyId}/talent/profile/${userId}-${new Date().getTime()}.${
      imageFileType2[0]
    }`;
  };

  return (
    <Page loading={gettingProfile}>
      <BrancherSnackbar
        controlClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        message="Profile form saved!"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
      <Grid container className={styles.parentContainer} item xs={12} justifyContent="center">
        <Grid container item xs={10} justifyContent="center">
          <Grid container item xs={12}>
            <Text variant="xl" color="purple" fontWeight={700} marginBottom={40} marginTop={40}>
              Update your profile
            </Text>
          </Grid>
          <Grid container item xs={12}>
            <Text variant="md" color="purple" fontWeight={600} marginBottom={40} marginTop={10}>
              Base profile information
            </Text>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Box marginBottom={3}>
              <BrancherFileUpload
                value={profile?.baseProfile?.[EProfileAttributes.IMAGE]}
                name={EProfileAttributes.IMAGE}
                updateValue={(c: string) => {
                  saveBaseProfileData(EProfileAttributes.IMAGE, createFileName(c));
                  BrancherDispatch(dispatch, SaveProfileData({ newProfilePic: c }));
                }}
              />
            </Box>
            <Text variant="sm" color="purple" fontWeight={600} marginBottom={10} marginLeft={30}>
              Upload photo
            </Text>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.FIRST_NAME]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.FIRST_NAME, a)}
                label="First name"
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.LAST_NAME]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.LAST_NAME, a)}
                label="Last name"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.JOB_TITLE]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.JOB_TITLE, a)}
                label="Job title"
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.LINKEDIN]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.LINKEDIN, a)}
                label="LinkedIn"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.ORGANISATION]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.ORGANISATION, a)}
                label="Organisation/Department/Division"
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.LOCATION]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.LOCATION, a)}
                label="Location"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.CONTACT_EMAIL]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.CONTACT_EMAIL, a)}
                label="Contact email"
                disabled
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.PHONE_NUMBER]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.PHONE_NUMBER, a)}
                label="Phone"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <BrancherTextField
                value={profile?.baseProfile?.[EProfileAttributes.ABOUT]}
                updateValue={(a: string) => saveBaseProfileData(EProfileAttributes.ABOUT, a)}
                label="About me"
                fullWidth
                rows={4}
                multiline
                maxChars={500}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BrancherDivider marginBottom={30} marginTop={30} />
          </Grid>

          <Grid container item xs={10} justifyContent="center">
            <Grid item container xs={12}>
              <Text variant="md" color="purple" fontWeight={600} marginBottom={40} marginTop={40}>
                Talent profile
              </Text>
            </Grid>
            <Grid item container xs={12}>
              <EditTalentProfile saveTalentProfile={saveTalentProfileData} talentProfile={profile?.profile} />
            </Grid>
          </Grid>

          {validations.length > 0 && (
            <Grid item>
              <Text variant="sm" color="red" fontWeight={500} marginBottom={20}>
                Please enter {validations?.join(', ')} {validations.length > 1 ? 'fields' : 'field'}{' '}
                to complete your profile
              </Text>
            </Grid>
          )}
          <Grid item container xs={12} className={styles.fixedActionPane}>
            <Grid container justifyContent="center" className={styles.paneButtonContainer}>
              <SaveButton onClick={saveProfile} loading={saving} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
