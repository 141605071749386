import { MuiAppBar } from './MuiAppBar';
import { MuiBackdrop } from './MuiBackdrop';
import { MuiButton } from './MuiButton';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiCircularProgress } from './MuiCircularProgress';
import { MuiDialog } from './MuiDialog';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiPickerDTTabs } from './MuiPickerDTTabs';
import { MuiPopover } from './MuiPopover';
import { MuiSnackbar } from './MuiSnackbar';
import { MuiSelect } from './MuiSelect';
import { MuiSlider } from './MuiSlider';
import { MuiSnackbarContent } from './MuiSnackbarContent';
import { MuiStepper } from './MuiStepper';
import { MuiStepContent } from './MuiStepContent';
import { MuiStepLabel } from './MuiStepLabel';
import { MuiSvgIcon } from './MuiSvgIcon';
import { MuiSwitch } from './MuiSwitch';
import { MuiTab } from './MuiTab';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup';
import {
  MuiTypography, ralewayFontBold, ralewayFontExtraBold, ralewayFontMedium, ralewayFontRegular, ralewayFontSemiBold,
} from './MuiTypography';
import { Colors } from '../consts/colors';

export interface IBrancherThemePalette {
  palette: {
    primary: {
      main: string;
    },
    secondary: {
      main: string;
    },
  };
}

export const BrancherTheme = (theme: IBrancherThemePalette): any => ({
  palette: theme.palette,
  overrides: {
    MuiAppBar: MuiAppBar(theme),
    MuiBackdrop: MuiBackdrop(),
    MuiButton: MuiButton(theme),
    MuiCheckbox: MuiCheckbox(theme),
    MuiCircularProgress: MuiCircularProgress(theme),
    MuiCssBaseline: {
      '@global': {
        html: {
          overflowX: 'hidden',
        },
        body: {
          padding: `0 !important`,
          margin: 0,
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          '&::-webkit-scrollbar': {
            width: 8,
          },
          '&::-webkit-scrollbar-track': {
            background: Colors.backgroundDarkPurple,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,    /* color of the scroll thumb */
            borderRadius: 10,      /* roundness of the scroll thumb */
            border: `3px solid transparent`,  /* creates padding around scroll thumb */
          },
        },
        a: {
          textDecoration: 'none',
        },
        '@font-face': [
          ralewayFontRegular,
          ralewayFontMedium,
          ralewayFontSemiBold,
          ralewayFontBold,
          ralewayFontExtraBold,
        ],
      },
    },
    MuiDialog: MuiDialog(theme),
    MuiDialogContent: MuiDialogContent(theme),
    MuiFormHelperText: MuiFormHelperText(),
    MuiLinearProgress: MuiLinearProgress(theme),
    MuiPickerDTTabs: MuiPickerDTTabs(),
    MuiPopover: MuiPopover(),
    MuiSelect: MuiSelect(theme),
    MuiSlider: MuiSlider(),
    MuiStepContent: MuiStepContent(),
    MuiStepLabel: MuiStepLabel(theme),
    MuiStepper: MuiStepper(),
    MuiSnackbar: MuiSnackbar(),
    MuiSnackbarContent: MuiSnackbarContent(theme),
    MuiSvgIcon: MuiSvgIcon(theme),
    MuiSwitch: MuiSwitch(theme),
    MuiTab: MuiTab(),
    MuiTabs: MuiTabs(),
    MuiTextField: MuiTextField(theme),
    MuiToggleButton: MuiToggleButton(theme),
    MuiToggleButtonGroup: MuiToggleButtonGroup(),
  },
  typography: MuiTypography,
});
