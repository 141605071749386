import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiToggleButton = (theme: IBrancherThemePalette) => ({
  root: {
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main} !important`,
    borderRadius: `15px !important`,
    marginTop: 20,
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 48,
    height: 'auto',
    backgroundColor: Colors.white,
    boxShadow:
      '0px 2px 5px -1px rgba(0,0,0,0.07),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.07)',
    '&:hover': {
      backgroundColor: Colors.white,
    },
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    // '&$selected': {
    //   backgroundColor: Colors.purple,
    //   '&:hover': {
    //     backgroundColor: Colors.purple,
    //   },
    // },
  },
});
