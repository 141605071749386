import { MobileFontSizes, TabletDesktopFontSizes } from '../consts/FontSizes';
import { DesktopFontWeights, MobileFontWeights, TabletFontWeights } from '../consts/FontWeights';
import { WindowSizeValues, WindowSizeVariants } from '../types/WindowSizeTypes';
import { TextVariantValues } from '../types/TextTypes';

export function getFontSize(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping = windowSize === WindowSizeVariants.mobile ? MobileFontSizes : TabletDesktopFontSizes;
  return sizesMapping[variant];
}

export function getFontWeight(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping =
    windowSize === WindowSizeVariants.mobile ? MobileFontWeights
      : windowSize === WindowSizeVariants.tablet ? TabletFontWeights
        : DesktopFontWeights;
  return sizesMapping[variant];
}
