import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { NegativeButton } from '../InputFields/BrancherButton';
import { Text } from './Text';

const useStyles = makeStyles({
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noOverflow: {
    '&&.MuiDialogContent-root': {
      overflowY: (props: { noOverflow: boolean, contentWidth: boolean }) => (props.noOverflow ? 'hidden' : 'auto'),
    },
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: (props: { noOverflow: boolean, contentWidth: boolean }) => (props.contentWidth ? 900 : '100%'),
    },
  },
});

export interface IBrancherDialog extends DialogProps {
  setClose: () => void;
  labelledBy: string;
  noOverflow?: boolean;
  contentWidth?: boolean;
}

export const BrancherDialog = (props: IBrancherDialog) => {
  const { setClose, open, children, title, labelledBy, noOverflow, contentWidth, className } = props;
  const styles = useStyles({ noOverflow, contentWidth });
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  return (
    <Dialog
      open={open}
      onClose={setClose}
      fullScreen={mobile}
      aria-labelledby={labelledBy}
      className={cx(styles.dialog, className)}
    >
      <Box right={24} position="absolute" mt={2} className={styles.closeIcon}>
        <NegativeButton variant="text" onClick={setClose} />
      </Box>
      {title && (
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Text variant="md" fontWeight={600} marginTop={65} align="center">
              {title}
            </Text>
          </Grid>
        </Grid>
      )}
      <DialogContent className={styles.noOverflow}>{children}</DialogContent>
    </Dialog>
  );
};
