export const GetLinkBase = (): string => {
  return 'https://media.brancher.com.au/';
};

export const GetMentoringLinkBase = (): string => {
  return 'https://mentoring.brancher.com.au/';
}

export const GetIllustrationLink = (fileName: string): string => {
  return `${GetLinkBase()}illustrations/${fileName}`;
};

export const GetSharedResourceLink = (fileName: string): string => {
  return `${GetLinkBase()}shared/${fileName}`;
};

export const privacyPolicyLink =
  `${GetLinkBase()}policies/Privacy-Policy.pdf`;

export const endUserTermsLink =
  `${GetLinkBase()}policies/End-User-License-Agreement.pdf`;

export const BackSplash =
  `${GetLinkBase()}shared/backSplash.png`;
