import * as React from 'react';
import Slider, { SliderProps } from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import { Colors } from '../../consts/colors';
import { Text } from '../General/Text';

export interface IBrancherSlider extends SliderProps {
	updateValue: (val: number | number[]) => void;
	likertScale?: boolean;
	hideLabelMarks?: boolean;
	question?: string;
}

export const BrancherSlider: React.FC<IBrancherSlider> = (props) => {
	const {
		updateValue, defaultValue = 1, step = null, valueLabelDisplay = 'off', likertScale = false, hideLabelMarks = false, question,
		...other
	} = props;
	const marks = (): SliderProps['marks'] => {
		if(hideLabelMarks) {
			return new Array(other.max).fill(0).map((b, o) => ({value: o+1, label: `.`}));
		} else if(!other.marks) {
			if(likertScale) {
				return [
					{value: 0, label: 'Strongly disagree'},
					{value: 1, label: 'Disagree'},
					{value: 2, label: 'Neutral'},
					{value: 3, label: 'Agree'},
					{value: 4, label: 'Strongly agree'},
				];
			} else {
				return new Array(other.max).fill(0).map((b, o) => ({value: o+1, label: `${o+1}`}));
			}
		} else {
			return other.marks;
		}
	};

	return (
		<>
			{question && (
				<Text variant="sm" marginTop={30} marginBottom={30} fontWeight={600} id={other.id ?? other.name}>
					{question}
				</Text>
			)}
			<Box width="100%" display="flex" pt={2} pb={2} pl={8} pr={8} bgcolor={Colors.backgroundLightPurple} borderRadius={15}>
				<Slider
					marks={marks()}
					step={step}
					valueLabelDisplay={valueLabelDisplay}
					defaultValue={defaultValue}
					onChange={(e, v) => updateValue(v)}
					max={likertScale ? 4 : 5}
					min={likertScale ? 0 : 1}
					aria-labelledby={other.id ?? other.name}
					{...other}
				/>
			</Box>
		</>
	);
};
