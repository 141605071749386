import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetTalentConfiguration } from '../../store/actions';
import { EComponentType, ITalentQuestions } from '../../store/actions/ConfigurationActions';
import { TextControl } from './FormFieldTypes/TextControl';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherSlider } from '../../Components/InputFields/BrancherSlider';

interface IEditTalentProfile {
  saveTalentProfile: (attribute: string, val: string | string[]) => void;
  talentProfile: {[x in string]: string | string[]};
}

export const EditTalentProfile: React.FC<IEditTalentProfile> = ({saveTalentProfile, talentProfile}) => {
  const [gettingConfiguration, setGettingConfiguration] = React.useState<boolean>(false);
  const [talentConfiguration, setTalentConfiguration] = React.useState<ITalentQuestions[]>([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingConfiguration(true);
    BrancherDispatch(dispatch, UtilGetTalentConfiguration((config) => {
      if(config.success) {
        setTalentConfiguration(config.data?.configuration);
      }
      setGettingConfiguration(false);
    }));
  }, []);

  return (
    <Page loading={gettingConfiguration}>
      {talentConfiguration.map((question, questionIndex) => {
        // @ts-ignore
        const { componentType, options, question: labelQuestion, ...other } = question;
        if (componentType === EComponentType.DROPDOWN) {
          return <BrancherSelect
            key={questionIndex}
            value={talentProfile?.[question.name] ?? []}
            options={options}
            inputLabel={labelQuestion}
            updateValue={(v) => saveTalentProfile(question.name, v)}
            {...other}
          />
        } else if (componentType === EComponentType.TEXT) {
          return <TextControl
            key={questionIndex}
            question={other.label}
            value={talentProfile?.[question.name].toString()}
            updateValue={(v) => saveTalentProfile(question.name, v.toString())}
            {...other}
          />
        } else if (componentType === EComponentType.SLIDER) {
          return <BrancherSlider
            key={questionIndex}
            question={other.label}
            value={Number(talentProfile?.[question.name])}
            updateValue={(v) => saveTalentProfile(question.name, v.toString())}
            {...other}
          />
        }
      })}
    </Page>
  );
};
