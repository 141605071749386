import { IAPIResponseObject } from './actionTypes/apiTypes';
import { BrancherAuthRequest } from './utils/BrancherDispatch';

export enum EJobStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export interface IJobPost {
  jobId: string; // primary
  companyId: string; // secondary
  title: string;
  description: string;
  status: EJobStatus;
  jobData: {[x in string]: any};
}

interface IUtilGetTalentJobPosts extends IAPIResponseObject {
  data: IJobPost[];
}

export const UtilGetTalentJobPosts = (cb: (a: IUtilGetTalentJobPosts) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/jobs',
        params: JSON.stringify({}),
      },
      getState(),
    )
      .then((response: any) => {
        const jobs = response.data;
        cb(jobs);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetTalentJobPost extends IAPIResponseObject {
  data: IJobPost;
}

export const UtilGetTalentJobPost = (jobId: string, cb: (a: IUtilGetTalentJobPost) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'talent/job',
        params: JSON.stringify({
          jobId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        const job = response.data;
        cb(job);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilSaveTalentJobPost extends IAPIResponseObject {
  data: IJobPost;
}

export const UtilSaveTalentJobPost = (jobPost: Omit<IJobPost, 'jobId' | 'companyId'>, cb: (a: IUtilSaveTalentJobPost) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'talent/job',
        data: {
          ...jobPost
        },
      },
      getState(),
    )
      .then((response: any) => {
        const job = response.data;
        cb(job);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
