import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import { BrancherDivider, BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { GetRoute } from '../../Components/Routing';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { SaveUserInfo, UtilLogin, UtilSSOSAMLCompatible } from '../../store/actions';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';
import { IStoreTypes } from '../../store/storeTypes';
import { BackSplashImagery } from './BackSplashImagery';
import { SSOSignIn } from './SSOSignIn';
import { EMFAChallengeNames, IUtilLoginResponse, IUtilLoginUserResponse } from '../../store/actions/UserInfoActions';
import { loginRedirectUri } from '../../consts/cognito';
import { decodeUserToken } from '../../utils/decodeUserToken';
import { SSOTypes } from '../SignUp/SSOSignUp';
import { ESessionAttributes, getSessionAttribute, setSessionAttribute } from '../../utils/cookies';
import { GetLinkBase, GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { MFAVerify } from './MFAVerify';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { SAMLSignIn } from './SAMLSignIn';

const useStyles = makeStyles({
  logo: {
    maxHeight: 180,
    height: 'auto',
    width: 180,
  },
});

export const Login = () => {
  const expiredToken = useSelector((state: IStoreTypes) => state.user?.expiredToken);
  const customLogo = useSelector((state: IStoreTypes) => state.user?.customLogo);
  const googleAuth = useSelector((state: IStoreTypes) => state.user?.googleSignOn);
  // const passwordAuth = useSelector((state: IStoreTypes) => state.user.passwordSignOn);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signedUp, hasSignedUp] = React.useState<boolean>(false);
  const [waiting, updateWaiting] = React.useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [apiError, setApiError] = React.useState('');
  const [redirectToVerifyConfirmation, setRedirectToVerifyConfirmation] = React.useState(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles();

  // sso
  const [isSSO, setIsSSO] = React.useState<boolean>(false);
  const [isSAMLSSO, setIsSAMLSSO] = React.useState<boolean>(false);
  const [gettingSAMLCompatibility, setGettingSAMLCompatibility] = React.useState<boolean>(false);
  const [mfaSetupSuccessful, setMFASetupSuccessful] = React.useState<boolean>(false);
  const [ssoUserState, setSSOUserState] = React.useState<IUtilLoginUserResponse['data']>();

  // mfa states
  const [secretCode, setSecretCode] = React.useState<string>('');
  const [mfaAccessToken, setMFAAccessToken] = React.useState<string>('');
  const [session, setSession] = React.useState<string>('');
  const [challengeName, setChallengeName] = React.useState<EMFAChallengeNames>(null);

  const login = () => {
    const errors = validateFields();
    if (errors.length === 0) {
      updateWaiting(true);
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(
        UtilLogin(email, password, (response) => {
          if (response.success) {
            if(response.data?.isMFA) {
              setSecretCode(response.data?.keyCode);
              setMFAAccessToken(response.data?.accessToken);
              setSession(response.data?.session);
              setChallengeName(response.data?.challengeName);
            } else {
              const {
                accessToken, idToken, companyId, companyName, loggedInPlatform, firstName, lastName, positions,
                whiteLabel, customLogo, googleSignOn,
              } = response.data;
              const userInfo: {
                sub: string; email: string; username: string; phone_number: string; exp: number;
              } = jwtDecode(idToken);
              const UserData = {
                id: userInfo.sub, email: userInfo.email, username: userInfo.username || userInfo.email,
                phoneNumber: userInfo.phone_number, tokenExp: userInfo.exp, IDToken: idToken,
                selectedCompanyId: companyId, firstName, lastName, accessToken, companyId, companyName,
                loggedInPlatform, loggedIn: true, positions, whiteLabel, customLogo, googleSignOn,
              };
              dispatch(SaveUserInfo(UserData));
              setSessionAttribute(ESessionAttributes.USER_EMAIL, UserData.email);
              hasSignedUp(true);
              updateWaiting(false);
            }
          } else {
            dispatch(SaveUserInfo({ username: email }));
            if (response.message === '"User is not confirmed."') {
              setRedirectToVerifyConfirmation(true);
            }
            updateWaiting(false);
            setApiError(response.message);
          }
        }),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  const getSAMLCompatibility = () => {
    setGettingSAMLCompatibility(true);
    dispatch(UtilSSOSAMLCompatible(email,(isCompatible) => {
      setGettingSAMLCompatibility(false);
      setApiError('');
      dispatch(SaveUserInfo({googleSignOn: false, samlSignOn: true}));
      if(!!isCompatible.data?.compatible) {
        setIsSSO(true);
        setIsSAMLSSO(true);
      }
    }));
  };

  const isLoginDisabled = (): boolean => {
    return (waiting && !signedUp) || !!gettingSAMLCompatibility || !checkSAMLData() || waiting;
  };

  const checkSAMLData = (): boolean => {
    if(isSAMLSSO) {
      return !!ssoUserState?.username;
    } else {
      return true;
    }
  };

  const emailOnBlur = (e) => {
    if(email !== '' && (e.target.value === email)) {
      getSAMLCompatibility();
    }
  };

  const CreateSuccessfulLoginData = (resData: IUtilLoginResponse['data']) => {
    const {
      idToken, companyId, companyName, firstName, lastName,
      positions, username, tokenExp, userSub, whiteLabel, customLogo, googleSignOn,
    } = resData;
    const tokenData = !isSSO ? decodeUserToken(idToken) : {
      username, email, id: userSub, IDToken: idToken, tokenExp
    };
    const successfulLoginData = {
      ...tokenData, loggedIn: true, expiredToken: false, redirected: false,
      firstName, lastName, companyId, companyName, positions, isSSO, whiteLabel,
      customLogo, googleSignOn,
    };
    dispatch(SaveUserInfo(successfulLoginData));
    setSessionAttribute(ESessionAttributes.USER_EMAIL, tokenData.email);
    hasSignedUp(true);
  };

  const controlSSOUser = (res: IUtilLoginUserResponse) => {
    window.history.replaceState({}, document.title, loginRedirectUri);
    const { message, data, success } = res;
    if(!success) {
      setApiError(message);
    } else {
      setEmail(data.email);
      setIsSSO(true);
      dispatch(SaveUserInfo({IDToken: data.idToken, tokenExp: data.tokenExp}));
      setSSOUserState(data);
      CreateSuccessfulLoginData(data);
    }
  };

  const validateFields = (): string[] => {
    const errors: string[] = [];
    if (email === '') {
      errors.push('email');
    }
    if (password === '') {
      errors.push('password');
    }
    return errors;
  };

  const postMFAResume = (reLogin?: boolean, keepWaiting?: boolean) => {
    setChallengeName(null);
    setMFAAccessToken('');
    setSession('');
    setSecretCode('');
    setMFASetupSuccessful(reLogin);
    updateWaiting(keepWaiting);
    if(reLogin) {
      login();
    }
  };

  return (
    <BackSplashImagery>
      <BrancherSnackbar
        controlClose={setMFASetupSuccessful}
        open={mfaSetupSuccessful}
        message="You have successfully setup your MFA! Please login to proceed."
      />
      {challengeName && (
        <MFAVerify
          challengeName={challengeName}
          secretCode={secretCode}
          session={session}
          programId=""
          accessToken={mfaAccessToken}
          setResume={postMFAResume}
          username={email}
          setSessionInformation={(s) => CreateSuccessfulLoginData(s)}
        />
      )}
      <Grid container alignItems="center" justifyContent="center">
        {redirectToVerifyConfirmation && <Redirect to={GetRoute('confirmRegistration').path} />}
        <Grid item xs={12}>
          <Grid container direction="column" justifyContent="center">
            <Grid item container justifyContent="center">
              <img
                src={customLogo ? `${GetLinkBase()}${customLogo}` : `${GetSharedResourceLink('brancher.png')}`}
                alt="brancher-logo"
                className={styles.logo}
              />
            </Grid>
            <BrancherDottedDivider marginTop={20} marginBottom={30} />
            {apiError && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15} fontWeight={600}>
                {apiError}
              </Text>
            )}
            {expiredToken && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15} fontWeight={600}>
                Your session has expired, please log back in to restart a secure session.
              </Text>
            )}
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item xs={12}>
                <BrancherTextField
                  fullWidth
                  name="email"
                  type="email"
                  value={email}
                  autoCapitalize="off"
                  onBlur={emailOnBlur}
                  updateValue={setEmail}
                  placeholder="Your Email Address"
                  label="Email Address"
                  error={fieldErrors.includes('email')}
                  helpText={fieldErrors.includes('email') && 'Please enter a valid email!'}
                />
              </Grid>
              <Grid item xs={12}>
                <BrancherTextField
                  fullWidth
                  name="password"
                  type="password"
                  value={password}
                  updateValue={setPassword}
                  placeholder="Enter Password"
                  label="Password"
                  error={fieldErrors.includes('password')}
                  helpText={fieldErrors.includes('password') && 'Please enter a stronger password!'}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Box marginTop={5} />
              <NextButton fullWidth onClick={login} disabled={isLoginDisabled()}>
                {waiting && !signedUp ? (
                  <CircularProgress color="primary" size={30} />
                ) : signedUp ? (
                  <Redirect to={GetRoute('home').path} />
                ) : (
                  'Login'
                )}
              </NextButton>
            </Grid>
            <Box display="flex" justifyContent="center" marginTop={mobile ? 2 : 3}>
              <BrancherRouterLink to={GetRoute('forgotPassword').path}>
                <Text variant="sm" color="purple">
                  Forgot Password?
                </Text>
              </BrancherRouterLink>
            </Box>
            {googleAuth && (
              <>
                <BrancherDivider marginTop={30} />
                <Text variant="sm" marginTop={20} align="center" fontWeight={500} color="tertiaryGrey">OR</Text>
                <Grid item container justifyContent="center">
                  <Box marginTop={mobile ? 2 : 3}>
                    <SSOSignIn controlSSOUser={controlSSOUser} type={SSOTypes.GOOGLE} />
                  </Box>
                </Grid>
              </>
            )}
            {(isSAMLSSO || getSessionAttribute(ESessionAttributes.HAS_SAML) === 'true') && (
              <Grid item container justifyContent="center">
                <Box marginBottom={mobile ? 2 : 3} marginTop={mobile ? 2 : 3}>
                  <SAMLSignIn
                    setApiError={setApiError}
                    email={email}
                    controlSSOUser={controlSSOUser}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
