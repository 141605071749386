import * as React from 'react';
import LockOpen from '@material-ui/icons/LockOpen';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { SaveUserInfo, UtilSSOSAMLLogin, UtilSSOValidateSAML } from '../../store/actions';
import { IUtilLoginUserResponse } from '../../store/actions/UserInfoActions';
import { ESessionAttributes, setSessionAttribute } from '../../utils/cookies';

interface ISAMLSignIn {
	setApiError: (res: string) => void;
	email: string;
	controlSSOUser: (res: IUtilLoginUserResponse) => void;
}

export const SAMLSignIn: React.FC<ISAMLSignIn> = ({setApiError, email, controlSSOUser}) => {
	const params = new URLSearchParams(useLocation()?.hash?.substring(1));
	const [gettingRedirectUri, setGettingRedirectUri] = React.useState<boolean>(false);
	const idToken = params?.get('id_token');
	const dispatch = useDispatch();

	React.useEffect(() => {
		getSSORedirectUri();
	}, []);

	React.useEffect(() => {
		if(!!idToken) {
			dispatch(UtilSSOSAMLLogin(idToken,(res) => {
				dispatch(SaveUserInfo({IDToken: idToken}));
				controlSSOUser(res);
			}));
		}
	}, [idToken]);

	const getSSORedirectUri = () => {
		setGettingRedirectUri(true);
		dispatch(UtilSSOValidateSAML(email,(samlRes) => {
			setGettingRedirectUri(false);
			if(samlRes.success) {
				setSessionAttribute(ESessionAttributes.HAS_SAML, 'true');
				window.location.assign(samlRes.data?.redirectUri);
			} else if(!!samlRes?.message) {
				setSessionAttribute(ESessionAttributes.HAS_SAML, 'false');
				setApiError(samlRes?.message);
			}
		}));
	};

	return (
		<BrancherButton startIcon={<LockOpen />} onClick={getSSORedirectUri} disabled={!email || gettingRedirectUri || !email?.includes('@')}>
			Single sign-on
		</BrancherButton>
	);
};
