export interface IValidatePassword {
  containsNumber: boolean;
  containsEnoughCharacters: boolean;
  containsUppercase: boolean;
  containsLowercase: boolean;
  containsSpecialCharacter?: boolean;
}

export const simpleValidatePassword = (password: string, passwordPolicy?: IValidationRules): boolean => {
  return Object.values(validatePassword(password, passwordPolicy)).reduce((a, b) => a && b);
};

export interface IValidationRules {
  minCharacters: number; // 8
  minNumbers: number; // 1
  minLowerCase: number; // 1
  minUpperCase: number; // 1
  minSpecialCharacters: number; // 0
}

export const validatePassword = (password: string, validationRules?: IValidationRules): IValidatePassword => {
  return {
    containsEnoughCharacters: hasEnoughChars(password, validationRules?.minCharacters),
    containsNumber: hasNumber(password, validationRules?.minNumbers),
    containsUppercase: hasUppercase(password, validationRules?.minUpperCase),
    containsLowercase: hasLowercase(password, validationRules?.minLowerCase),
    ...(validationRules?.minSpecialCharacters > 0 &&
      {containsSpecialCharacter: hasSpecialCharacter(password, validationRules?.minSpecialCharacters)}),
  };
};

const hasNumber = (password: string, validateAmount: number = 1): boolean => {
  const stringWithDigits = password.replace(/[^0-9]/g, '').length;
  return stringWithDigits >= validateAmount;
};

const hasEnoughChars = (password: string, validateAmount: number = 8): boolean => {
  return password.length >= validateAmount;
};

const hasUppercase = (password: string, validateAmount: number = 1): boolean => {
  const uppercasePattern = /[A-Z]/g;
  return password.match(uppercasePattern)?.length >= validateAmount;
};

const hasLowercase = (password: string, validateAmount: number = 1): boolean => {
  const lowercasePattern = /[a-z]/g;
  return password.match(lowercasePattern)?.length >= validateAmount;
};

const hasSpecialCharacter = (password: string, validateAmount: number = 0): boolean => {
  const specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
  return password.match(specialCharPattern)?.length >= validateAmount;
};
