import * as React from 'react';
import Box from '@material-ui/core/Box';
import { Text } from '../../Components/General/Text';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { endUserTermsLink, privacyPolicyLink } from '../../helpers/LinkHelpers';

interface IBrancherTnCs {
	value: boolean;
	setValue: (checked: boolean)=>void;
}

export const BrancherTnCs: React.FC<IBrancherTnCs> = ({ value, setValue }) => {
	return (
		<BrancherCheckbox
			name="agreeToPolicies"
			value={value}
			updateValue={setValue}
			label={
				<Box display="inline">
					<Text variant="xs" display="inline">I have read and agree to Brancher's</Text>{' '}
					<BrancherLinkText variant="xs" target="_blank" href={endUserTermsLink} display="inline">
						Terms
					</BrancherLinkText>
					<Text variant="xs" display="inline">{' '}and{' '}</Text>
					<BrancherLinkText variant="xs" target="_blank" href={privacyPolicyLink} display="inline">
						Privacy Policy
					</BrancherLinkText>
				</Box>
			}
		/>
	);
};
