import * as React from 'react';
import LockOpen from '@material-ui/icons/LockOpen';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { IUtilSignUpUserResponse, UtilSSOSAMLSignUp } from '../../store/actions/UserInfoActions';

interface ISSOSignUp {
	controlSSOUser: (res: IUtilSignUpUserResponse) => void;
	samlRedirectUri: string;
}

export const SAMLSignUp: React.FC<ISSOSignUp> = ({controlSSOUser, samlRedirectUri}) => {
	const params = new URLSearchParams(useLocation()?.hash?.substring(1));
	const [signingUpSAMLUser, setSigningUpSAMLUser] = React.useState<boolean>(false);
	const idToken = params?.get('id_token');
	const dispatch = useDispatch();

	const redirectToSAMLUri = () => {
		window.location.assign(samlRedirectUri);
	};

	React.useEffect(() => {
		if(!!idToken) {
			setSigningUpSAMLUser(true);
			dispatch(UtilSSOSAMLSignUp(idToken,(res) => {
				controlSSOUser(res);
				setSigningUpSAMLUser(false);
			}));
		}
	}, [idToken]);

	return (
		<BrancherButton startIcon={<LockOpen />} onClick={redirectToSAMLUri} disabled={!!idToken || signingUpSAMLUser}>
			Single sign-on
		</BrancherButton>
	);
};
